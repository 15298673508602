$font: Source Sans Pro, sans-serif;
$font-2: HelveticaNeue;
$font-3: Roboto, sans-serif;
$font-4: Nunito Sans, sans-serif;
$primary-color: #42b8a4;
$primary-color-2: #239f8a;
$secondary-color: #3550d6;
$secondary-color-2: #4256b8;
$white: #ffffff;
$sub-head-color: #4b4b5a;
$heading-color: #6882ff;
$heading-color-2: #b8427d;
$text-color: #05192e;
$text-color-2: #dbdbdf;
$text-color-3: #868692;
$bg-color: #e2e6fb;
$bg-color-2: #0d0a3c;

$assetPath :"/dist/assets/images";
$fontsPath :"/dist/assets/fonts";