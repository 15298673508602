* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}

body {
  font-family: Source Sans Pro, sans-serif;
  background: #e2e6fb;
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url(/dist/assets/fonts/HelveticaNeueMedium.woff2) format("woff2"), url(/dist/assets/fonts/HelveticaNeueMedium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}