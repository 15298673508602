@use 'variables' as *;

* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
  }
  
  body {
    font-family: $font;
    background: $bg-color;
  }
  
  html {
    scroll-behavior: smooth;
  }